import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import OASection from "../../components/our-approach-section";
import blueOne from "../../images/1-blue.svg";
import blueTwo from "../../images/2-blue.svg";
import blueThree from "../../images/3-blue.svg";

const WebDesignPageDubai = ({ location }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img1: wpMediaItem(title: { eq: "design-latest" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			hiveImg: wpMediaItem(title: { eq: "Hivehr-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			googleSearchImg: wpMediaItem(
				title: { eq: "screencapture-neutralcarbonzone-2024-08-07-11_19_30" }
			) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Web Design Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Web Design",
				item: {
					url: `${siteUrl}/dubai/web-design`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/web-design`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Talk to a specialist"
						button1Link="#form"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`WEB DESIGN</br>AGENCY \n<span class="text-secondary">DUBAI</span>`}
						description="Experience best in class design, reliable execution, and ongoing support from a team that understands the high expectations of expats in Dubai."
					/>
				</section>

				<section id="intro" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7">
							<Col lg={6} className="mb-5 mb-lg-0">
								<h2 className="mb-5 display-5 text-primary">
									Stop Wasting Money on Websites That Don’t Work – Get a
									Lead-Generating Machine Instead!
								</h2>
								<div
									className="mb-4 d-lg-none"
									style={{ overflow: "hidden", borderRadius: "15px" }}
								>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1?.altText}
									/>
								</div>
								<p>
									Stop wasting time and money on slow, outdated websites that
									look nice but fail to deliver results. Imagine a site that’s{" "}
									<strong>uilt to convert visitors into leads</strong>b from the
									moment they land on it. With a **headless website approach**,
									your business gets all the speed, security, and flexibility of
									modern technology. Faster load times mean fewer drop-offs, and
									a seamless user experience keeps prospects moving toward that
									"Get in Touch" button. Every page, every click, every form —
									all crafted with one goal: **more leads, more sales, more
									business**.
								</p>
								<p>
									Here’s the reality — most web design agencies disappear as
									soon as the site is live. But the real work starts after
									launch. Support, updates, and continuous improvements are
									critical for long-term success. That’s why it’s essential to
									work with a partner who understands the{" "}
									<srong>local Dubai market</srong>, knows what expats expect,
									and stays by your side long after the site goes live. No more
									chasing down “support tickets” or dealing with unresponsive
									developers.{" "}
									<strong>Real-time support and ongoing improvements</strong>{" "}
									keep your site ahead of the competition, turning your website
									into a revenue-driving machine you can rely on 24/7.
								</p>
								<Button
									className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 white-link-yellow"
									variant="primary"
									as={Link}
									to="/dubai/contact-us"
								>
									Talk to a specialist
								</Button>
							</Col>
							<Col className="d-none d-lg-block" lg={6}>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="results" className="py-5 py-lg-7 bg-dark-grey">
					<Container className="mb-8">
						<Row className="justify-content-between gx-5">
							<Col lg={5} className="pb-5 position-relative d-none d-lg-block">
								<div
									style={{ borderRadius: " 15px", overflow: "hidden" }}
									className="mt-4 bg-white ms-auto w-100"
								>
									<GatsbyImage
										className="w-100 "
										image={data.googleSearchImg.gatsbyImage}
										alt={data.googleSearchImg?.altText}
									/>
								</div>
							</Col>
							<Col lg={7}>
								<h2 className="mb-5 text-white display-4 ssp-bold text-uppercase">
									Are You Searching for the best web design agency in Dubai?
									<br />
									Stop Searching —{" "}
									<span className="text-secondary">You’ve Just Found It. </span>
								</h2>
								<p className="text-white">
									If you're tired of flashy websites that *look* good but fail
									to deliver leads, you’re not alone. You don’t need "just
									another website" — you need a powerful, conversion-driven
									platform that works around the clock to grow your business.
									That’s where <strong>headless websites</strong> come in.
									Unlike traditional sites, headless websites give you{" "}
									<strong>faster load times</strong>,{" "}
									<strong>unmatched security</strong>, and the flexibility to
									scale as your business grows. If your competitors are still
									running old-school sites, you’ve already got the upper hand.
								</p>
								<p className="text-white">
									Instead of a one-size-fits-all template, you get a site built
									around <strong>your unique business needs</strong>. Every
									click, every call-to-action, and every page is designed with
									one goal:{" "}
									<strong>turning visitors into paying customers</strong>.
									Whether you’re targeting Dubai locals or UK and European
									expats, your website will be perfectly positioned to{" "}
									<strong>stand out in a crowded market</strong>. You’ll have
									access to <strong>real-time analytics</strong> that tell you
									exactly how your site is performing — what’s working, what’s
									not, and where you can squeeze out even more conversions. This
									isn’t just "website design." This is{" "}
									<strong>business growth on autopilot</strong>. If you’re ready
									to stop wasting money on 'pretty' websites and start seeing
									results, it’s time to demand more from your web design agency.{" "}
									<strong>
										Your customers are searching for you right now. Will they
										find you — or your competitor?
									</strong>
								</p>
								<Button
									className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 primary-link-dark"
									variant="white"
									href="#form"
								>
									Talk to an expert
								</Button>
							</Col>
						</Row>
					</Container>
				</section>

				<div id="approach">
					<OASection
						heading="OUR 4-STEP APPROACH"
						dubai
						imgHeight="60rem"
						image={data.hiveImg.gatsbyImage}
						imageAlt={data.hiveImg?.altText}
						launch="After the RJM team ensures that everything is in order and you're ready to go, we can launch your website. Our friendly support team will then be available to you to resolve any issues that may arise and to make any future changes to your site as and when you need to."
						build="RJM Digital uses the most up-to-date technology to make sure your Dubai businesses new website is quick and accessible on any device. We will test your site thoroughly to ensure your users will have a great user experience and it is fully prepped for SEO, so that it can be found on search engines."
						design="Once we have understood your requirements for the design, we will now begin crafting ideas for your website. We will engage with you throughout this stage and keep you up-to-date with our progress with regular check-in sessions to make any necessary changes until you are completely satisfied with the final result."
						brief="Our expert web design Dubai team will make building or redesigning your website seem like a walk in the park. We will guide you through the process and ensure that your website not only represents your brand but also acts as an important asset in your marketing campaigns."
					/>
				</div>
				<section id="benefits" className="py-5 p-lg-7 bg-dark-grey">
					<Container className="">
						<Row>
							<Col>
								<h2 className="mb-5 text-white display-4 ssp-bold text-uppercase">
									Why your <span className="text-light-blue">Dubai</span>{" "}
									business should invest in a{" "}
									<span className="text-light-blue">new website</span>
								</h2>
							</Col>
						</Row>
						<Row className=" g-6 g-lg-4 justify-content-center">
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="text-center position-absolute w-100 h-100"
								>
									<img src={blueOne} className="w-md-40 w-30" />
								</div>
								<div className="pt-5 px-xl-5 position-relative">
									<h3 className="text-white fs-1">Stand out from the crowd</h3>
									<p className="text-white">
										A professionally designed and developed website by a web
										design agency in Dubai will allow your business to stand out
										from competitors in Dubai and beyond. Your new website will
										incorporate advanced features such as super fast loading
										speeds that will help to differentiate your business in the
										market.
									</p>
								</div>
							</Col>
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="text-center position-absolute w-100 h-100"
								>
									<img src={blueTwo} className="w-md-40 w-30" />
								</div>
								<div className="pt-5 px-xl-5 position-relative">
									<h3 className="text-white fs-1">More Visibility</h3>
									<p className="text-white">
										By using the best search engine optimisation (SEO)
										techniques, your new website will gain greater visibility in
										search engine results pages. Therefore helping to drive
										organic traffic to your website.
									</p>
								</div>
							</Col>
							<Col className="position-relative " md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="text-center position-absolute w-100 h-100"
								>
									<img src={blueThree} className="w-md-40 w-30" />
								</div>
								<div className="pt-5 px-xl-5 position-relative">
									<h3 className="text-white fs-1">Mobile-Friendly Design</h3>
									<p className="text-white">
										With more users accessing the internet through mobile
										devices, having a website optimised for mobile is essential.
										We will design your website to be responsive and
										mobile-friendly, ensuring a seamless user experience across
										various devices.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="pt-5 pt-lg-7 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your project.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>

				<div id="faq">
					<Faq page="Web Design" />
				</div>
				{/* <OSSection
          dubai
          link1="/dubai/seo"
          link3="/dubai/sustainable-web-design"
          text3="SUSTAINABLE WEBSITES"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1?.altText}
        /> */}
			</Layout>
		</>
	);
};

export default WebDesignPageDubai;
