import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const OASection = ({
	image,
	imageAlt,
	heading,
	brief,
	design,
	build,
	launch,
	heading1,
	heading2,
	heading3,
	heading4,
	imgHeight,
	dubai,
	smallHeading,
	textClass,
	buttonUrl,
	buttonText,
	customHeading,
}) => {
	const headingNew = heading || "OUR APPROACH";
	const heading1Text = heading1 || "BRIEF";
	const heading2Text = heading2 || "DESIGN";
	const heading3Text = heading3 || "BUILD";
	const heading4Text = heading4 || "LAUNCH";
	const btnText = buttonText || "Get in touch";
	const buttonLink =
		buttonUrl || `${dubai ? "/dubai/contact-us" : "/contact-us"}`;
	const launchText =
		launch ||
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
	const buildText =
		build ||
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
	const designText =
		design ||
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
	const briefText =
		brief ||
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
	return (
		<section
			style={{
				background:
					"transparent linear-gradient(58deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
			}}
			className="py-5 py-lg-7"
		>
			<Container>
				<Row>
					<Col className={`${textClass}`}>
						<p className="ssp-bold text-uppercase text-light-blue">
							{smallHeading}
						</p>
						{!customHeading && (
							<h2
								className={`display-5 pb-5 text-white ${
									image === null ? "text-center" : ""
								}`}
							>
								{headingNew}
							</h2>
						)}
						{customHeading && <div>{parse(headingNew)}</div>}
					</Col>
				</Row>
				<Row>
					<Col>
						<Row className="g-6 justify-content-center">
							{image !== null && (
								<Col className="d-none d-lg-block" lg={4}>
									<div
										style={{
											borderRadius: "15px",
											boxShadow: "0px 3px 99px #0000006A",
											overflow: "hidden",
											maxHeight: imgHeight,
										}}
										className="w-100 "
									>
										<GatsbyImage
											className="w-100 "
											image={image}
											alt={imageAlt}
											objectPosition="top center"
										/>
									</div>
								</Col>
							)}
							{image !== null && (
								<Col className="text-center" lg={8}>
									<Row className="g-5 text-start">
										<Col lg={6}>
											<h3 className="fs-1 text-uppercase pb-3 text-white">
												{heading1Text}
											</h3>
											<p className="text-white">{briefText}</p>
										</Col>
										<Col lg={6}>
											<h3 className="fs-1 text-uppercase pb-3 text-white">
												{heading2Text}
											</h3>
											<p className="text-white">{designText}</p>
										</Col>
										<Col lg={6}>
											<h3 className="fs-1 text-uppercase pb-3 text-white">
												{heading3Text}
											</h3>
											<p className="text-white">{buildText}</p>
										</Col>
										<Col lg={6}>
											<h3 className="fs-1 text-uppercase pb-3 text-white">
												{heading4Text}
											</h3>
											<p className="text-white">{launchText}</p>
										</Col>
									</Row>
									<Button
										className=" w-100 w-md-auto mt-4 cta-btn fs-5 primary-link-dark py-2 px-4 "
										variant="white"
										as={Link}
										to={buttonLink}
									>
										{btnText}
									</Button>
								</Col>
							)}
							{image === null && (
								<Col className="text-center" lg={12}>
									<Row className="g-5 text-start">
										<Col lg={6}>
											<h3 className="fs-1 text-uppercase pb-3 text-white">
												{heading1Text}
											</h3>
											<p className="text-white">{briefText}</p>
										</Col>
										<Col lg={6}>
											<h3 className="fs-1 text-uppercase pb-3 text-white">
												{heading2Text}
											</h3>
											<p className="text-white">{designText}</p>
										</Col>
										<Col lg={6}>
											<h3 className="fs-1 text-uppercase pb-3 text-white">
												{heading3Text}
											</h3>
											<p className="text-white">{buildText}</p>
										</Col>
										<Col lg={6}>
											<h3 className="fs-1 text-uppercase pb-3 text-white">
												{heading4Text}
											</h3>
											<p className="text-white">{launchText}</p>
										</Col>
									</Row>
									<Button
										className=" w-100 w-md-auto mt-4 cta-btn fs-5 primary-link-dark py-2 px-4 "
										variant="white"
										as={Link}
										to={buttonLink}
									>
										{btnText}
									</Button>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default OASection;
